:root {
  --cor-principal: #6b9af4;
}

body, html {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
  color: white;
}
.nav-link, .navbar-brand{
  color: white;
}

.cont2{
  margin-top: 100px;
}
.cont{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.eu{
  border-radius: 100%;
  width: 420px;
}

.ola{
  color: var(--cor-principal);
  font-weight: bold;
  font-family: 'Baloo 2', cursive;
}
.nome-titulo{
  color: var(--cor-principal);
  font-weight: bold;
}
.btn-cv{
  background-color: var(--cor-principal);
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
  border: none;
  font-size: 20px;
}
.btn-cv:hover{
  background-color: aliceblue;
  color: var(--cor-principal);
}
.dev-titulo{
  font-weight: bold;
}
.typing-container {
  font-family: monospace;
  display: inline-block;
}

.cursor {
  font-weight: 100;
  font-size: 1.5em;
  color: white;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.gradient-text {
  font-size: 30px;
  font-weight: bold;
  background: linear-gradient(90deg, var(--cor-principal), #f0e68c, #ffffff);
  background-size: 60%; 
  background-position: 0% 50%; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.nav-bar-desktop{
  display: block;
  background-color: #121212;
  padding-left: 200px;
  padding-right: 200px;
}

.nav-bar-mobile{
  display: none;
}

@media only screen and (max-width: 600px) {
  .eu {
    width: 200px; 
  }
  
  .nav-bar-desktop{
    display: none;
  }
  .nav-bar-mobile{
    display: block;
    background-color: #121212;
  }
}