:root {
    --cor-principal: #6b9af4;
  }
  
.container-skin{
    background-color: #121212;
    margin: 10px;
    font-size: 20px;
    text-align: center;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.container-skin:hover{
    box-shadow: 
        0 0 10px var(--cor-principal), 
        0 0 10px var(--cor-principal), 
        0 0 30px var(--cor-principal), 
        0 0 20px var(--cor-principal);
    background-color: #1e1e1e;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.icon{
    font-size: 30px;
}
.titulo-skin{
    color: var(--cor-principal);
    font-weight: bold;
    font-family: 'Baloo 2', cursive;
}
.cntn{
    background-color: #1e1e1e;
    padding: 20px;
    margin-top: 100px;
}