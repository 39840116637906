:root {
    --cor-principal: #6b9af4;
  }
  

.titulo-xp{
    color: var(--cor-principal);
    font-weight: bold;
    font-family: 'Baloo 2', cursive;
}
ul, li{
    list-style: none;
}
.titulo-vaga{
    font-weight: bold;

}
.container-xp{
    margin-top: 100px;
}