:root {
    --cor-principal: #6b9af4;
  }
  
.cntn{
    background-color: #1e1e1e;
    padding: 20px;
    margin-top: 100px;
}
.container-contato{
    text-align: center;
}
.icon-rede{
    padding: 20px;
    background-color: #121212;
    margin: 10px;
    border-radius: 100%;
    font-size: 30px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.icon-rede:hover{
    box-shadow: 
        0 0 10px var(--cor-principal), 
        0 0 10px var(--cor-principal), 
        0 0 30px var(--cor-principal), 
        0 0 20px var(--cor-principal);
    background-color: #1e1e1e;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.container-cv{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.container-redes-sociais{
    margin-top: 100px;
}
.svg-ilustration{
    width: 500px;
    animation: float 2s ease-in-out infinite;
}
@media only screen and (max-width: 600px) {
   
    .svg-ilustration{
      display: none;
    }
  }
@keyframes float {
    0% {
      transform: translateY(0); /* Posição inicial */
    }
    50% {
      transform: translateY(-20px); /* Sobe 20px */
    }
    100% {
      transform: translateY(0); /* Volta à posição inicial */
    }
}
